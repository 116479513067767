import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { TextProps } from '../../../types/types';
import Accordian from '../../fields/accordian';

interface FaqItemProps {
	title: TextProps;
	description: TextProps;
	accordianImageUp: any;
	accordianImageDown: any;
}

const FaqItem: React.FC<FaqItemProps> = ({ title, description, accordianImageUp, accordianImageDown }) => {
	//use hooks to make styles
	return (
		<div className='faq-item'>
			<Accordian accordianImageDown={accordianImageDown} accordianImageUp={accordianImageUp} title={title}>
				<p style={{ color: description.color }} dangerouslySetInnerHTML={{ __html: description.text }}></p>
			</Accordian>
		</div>
	);
};
export default FaqItem;
