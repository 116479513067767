import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { DownloadButtonData } from '../../../types/types';
import { ANALYTICS_CONSTANTS, THANKYOU_PAGE_PATH } from '../../../constants';
import AnalyticsContext from '../../../context/analytics/analyticsContext';
import { redirectCheck } from '../../../../utils/redirectCheck';
import PageContext from "../../../context/page/pageContext";


const DownloadButton = (props: DownloadButtonData) => {
	const analyticsPrefix = useContext(AnalyticsContext);
	const { apkPromise } = useContext(PageContext);
	
	const handleDownload = () => {
		let dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: analyticsPrefix + ANALYTICS_CONSTANTS.EVENT,
			category: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.CATEGORY,
			action: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.ACTION,
			label: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.LABEL
		});
		redirectCheck(apkPromise);
	};
	return (
		// <div className="hero-section-download-btn-container">
		// {/* <input type="number" style={{ color: props.placeholderText?.color }} placeholder={props.placeholderText.text} className="hero-section-input-field input-field-no-spin" maxLength={10} /> */}
		<div className='hero-section-download-link apk-download-link' onClick={handleDownload}>
			<div style={{ backgroundColor: props?.button?.buttonColor }} className='hero-section-download-btn-container'>
				{props.button.buttonAndroidImage && (
					<GatsbyImage
						image={props.button.buttonAndroidImage.imageFile}
						alt={props.button.buttonAndroidImage.altText}
						className='hero-section-os-image-field'
					/>
				)}
				<p style={{ color: props?.button?.buttonText?.color }} className='hero-section-download-text'>
					{props?.button?.buttonText?.text}
				</p>
			</div>
		</div>

		// </div>
	);
};

export default DownloadButton;
