import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';

import './testimonialSection.modules.css';
import UserTestimonial from './UserTestimonial';
import GenericSlider from '../../fields/genericSlider';
import { UserTestimonialProps } from '../../../types/types';
import processTestimonialSectionData from './helper';
import PageContext from '../../../context/page/pageContext';
import { ANALYTICS_CONSTANTS, THANKYOU_PAGE_PATH } from '../../../constants';
import AnalyticsContext from '../../../context/analytics/analyticsContext';
import { redirectCheck } from '../../../../utils/redirectCheck';

interface TestimonialSectionProps {
	userTestimonials: Array<UserTestimonialProps>;
	downloadIosImage: string;
	downloadAndroidImage: string;
	ratingImage: string;
	titletextHtml: string;
}

const constructComponentArray = (userTestimonials: Array<UserTestimonialProps>) => {
	const componentArray = [];
	userTestimonials.forEach((testimonialData, index) => {
		componentArray.push(<UserTestimonial {...testimonialData} />);
	});
	return componentArray;
};

const TestimonialSection = (props: TestimonialSectionProps) => {
	const { data, page, apkPromise } = useContext(PageContext);
	const analyticsPrefix = useContext(AnalyticsContext);

	const handleDownloadAndroid = () => {
		let dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: analyticsPrefix + ANALYTICS_CONSTANTS.EVENT,
			category: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.CATEGORY,
			action: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.ACTION,
			label: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.LABEL
		});
		redirectCheck(apkPromise);
	};
	const handleDownloadIos = () => {
		let dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: analyticsPrefix + ANALYTICS_CONSTANTS.EVENT,
			category: analyticsPrefix + ANALYTICS_CONSTANTS.IOS_APP_STORE_EVENT.CATEGORY,
			action: analyticsPrefix + ANALYTICS_CONSTANTS.IOS_APP_STORE_EVENT.ACTION,
			label: analyticsPrefix + ANALYTICS_CONSTANTS.IOS_APP_STORE_EVENT.LABEL
		});
	};

	const processedData = processTestimonialSectionData(data, page);
	const {
		userTestimonials,
		downloadAndroidImage,
		downloadIosImage,
		ratingImage,
		titleText,
		titleSubHeading,
		sliderButton,
		backgroundColor,
		winnerTitle
	} = processedData;
	const componentArray1 = constructComponentArray(userTestimonials);

	return (
		<React.Fragment>
			<div className='testimonials-container' style={{ backgroundColor: backgroundColor }}>
				<div className='testimonials-heading-container'>
					<div className='testimonials-heading-left'>
						{titleText && (
							<div className='testimonials-heading-text'>
								<h2 style={{ color: titleText.color }} className='testimonials-heading-title-text'>
									{titleText.text}
								</h2>
							</div>
						)}

						     {titleSubHeading && (<div className='subheadingmain' style={{color: titleSubHeading.color }}  dangerouslySetInnerHTML={{ __html: titleSubHeading.text }} ></div>)}

						<div className='testimonials-download-image-container' style={{ justifyContent: 'space-around' }}>
							{downloadAndroidImage?.image?.imageFile && (
								<div className='testimonials-download-image '>
									<div onClick={() => handleDownloadAndroid()} className='apk-download-link' style={{cursor: 'pointer'}}>
										<GatsbyImage
											image={downloadAndroidImage?.image?.imageFile}
											alt={downloadAndroidImage?.image?.altText}
										/>
									</div>
								</div>
							)}
							{downloadIosImage?.image?.imageFile && (
								<div className='testimonials-download-image'>
									<a href={downloadIosImage?.url} onClick={() => handleDownloadIos()} target='_blank'>
										<GatsbyImage
											image={downloadIosImage?.image?.imageFile}
											alt={downloadIosImage?.image?.altText}
										/>
									</a>
								</div>
							)}
						</div>
					</div>
					{ratingImage && (
						<div className='testimonials-rating-image'>
							{ratingImage && <GatsbyImage image={ratingImage.imageFile} alt={ratingImage.altText} />}
						</div>
					)}
				</div>
				<h2 className='testimonials-winner-title' style={{ color: winnerTitle.color }}>
					{winnerTitle.text}
				</h2>
				<div className='testimonials-generic-slider-container'>
					<GenericSlider
						componentArray={componentArray1}
						numberOfComponents={3}
						sliderButton={sliderButton}
						isSliderButtonEnabled={true}
						animationType={'translateHorizontally'}
					/>
				</div>
			</div>
		</React.Fragment>
	);
};
export default TestimonialSection;
